import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'state/store';


interface employeePaymentState {
    contributionDateFrom: null | string;
    contributionDateTo: null | string;
    transactionDateFrom: null | string;
    transactionDateTo: null | string;
}

const initialState: employeePaymentState = {
    contributionDateFrom: null,
    contributionDateTo: null,
    transactionDateFrom: null,
    transactionDateTo: null
};

// Slice
const EmployeePaymentSlice = createSlice({
    name: 'employeePayment',
    initialState,
    reducers: {
        setContributionDateFrom: (state, action: PayloadAction<null | string>) => {
            const { payload } = action;
            state.contributionDateFrom = payload;
        },
        setContributionDateTo: (state, action: PayloadAction<null | string>) => {
            const { payload } = action;
            state.contributionDateTo = payload;
        },
        setTransactionDateFrom: (state, action: PayloadAction<null | string>) => {
            const { payload } = action;
            state.transactionDateFrom = payload;
        },
        setTransactionDateTo: (state, action: PayloadAction<null | string>) => {
            const { payload } = action;
            state.transactionDateTo = payload;
        }
    }
});

// Reducers
export default EmployeePaymentSlice.reducer;

// Selectors
export const employeePaymentSelector = (state: RootState) => state.employee.payments;

// Actions
export const { setContributionDateFrom, setContributionDateTo, setTransactionDateFrom, setTransactionDateTo } = EmployeePaymentSlice.actions;