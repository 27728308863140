import React, { useMemo, useCallback } from "react"
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from "react-redux";
import { ToastContainer } from 'react-toastify';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { setToken, setUser } from "state/authentication";
import { useIdleTimer } from "react-idle-timer"
import store from "./src/state/store";
// import ComingSoonPage from "./src/coming_soon_page";

const queryClient = new QueryClient();

const theme = createTheme({
    typography: {
        fontFamily: [
            "Gelion",
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        primary: {
            main: "#1B4085"
        },
        secondary: {
            main: "#DCA096"
        }
    },
});


const SetLoggedUser = () => {
    const dispatch = useDispatch();
    const isBrowser = useMemo(() => typeof window !== 'undefined', []);
    const accessToken = useMemo(() => isBrowser && window.localStorage.getItem("access_token"), [isBrowser]);
    const accessTokenExpires = useMemo(() => isBrowser && window.localStorage.getItem("access_token_expires"), [isBrowser]);
    const user = useMemo(() => isBrowser && JSON.parse(window.localStorage.getItem("user")), [isBrowser]);
    const isAuthenticated = useCallback(() => {
        return accessToken && accessTokenExpires && Number(accessTokenExpires) > Math.floor(Date.now() / 1000) && user;
    }, [accessToken, accessTokenExpires, user])
    if (isAuthenticated()) {
        dispatch(setToken(accessToken));
        dispatch(setUser(user));
    }
    return null
}

const AddIdle = () => {
    const isBrowser = useMemo(() => typeof window !== 'undefined', []);
    if (isBrowser) {
        const onIdle = () => { }
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useIdleTimer({
            onIdle,
            timeout: 15 * 60 * 1000,
            promptTimeout: 0,
            events: [
                'mousemove',
                'keydown',
                'wheel',
                'DOMMouseScroll',
                'mousewheel',
                'mousedown',
                'touchstart',
                'touchmove',
                'MSPointerDown',
                'MSPointerMove',
                'visibilitychange'
            ],
            immediateEvents: [],
            debounce: 0,
            throttle: 0,
            eventsThrottle: 200,
            element: document,
            startOnMount: true,
            startManually: false,
            stopOnIdle: false,
            crossTab: false,
            name: 'idle-timer',
            syncTimers: 0,
            leaderElection: false
        })
    }
    return null;
}



export default ({ element }) => {
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <AddIdle />
                    <SetLoggedUser />
                    {element}
                    {/* <ComingSoonPage /> */}
                    <ToastContainer
                        theme="light"
                        position="top-center"
                        closeButton={false}
                        hideProgressBar={true}
                        closeOnClick={true}
                    />
                </Provider>
            </ThemeProvider>
        </QueryClientProvider>
    )
}


