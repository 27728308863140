exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-admin-company-index-tsx": () => import("./../../../src/pages/admin/company/index.tsx" /* webpackChunkName: "component---src-pages-admin-company-index-tsx" */),
  "component---src-pages-admin-employee-index-tsx": () => import("./../../../src/pages/admin/employee/index.tsx" /* webpackChunkName: "component---src-pages-admin-employee-index-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-company-contact-us-index-tsx": () => import("./../../../src/pages/company/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-company-contact-us-index-tsx" */),
  "component---src-pages-company-dashboard-index-tsx": () => import("./../../../src/pages/company/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-company-dashboard-index-tsx" */),
  "component---src-pages-company-documents-faq-index-tsx": () => import("./../../../src/pages/company/documents/faq/index.tsx" /* webpackChunkName: "component---src-pages-company-documents-faq-index-tsx" */),
  "component---src-pages-company-documents-glossary-index-tsx": () => import("./../../../src/pages/company/documents/glossary/index.tsx" /* webpackChunkName: "component---src-pages-company-documents-glossary-index-tsx" */),
  "component---src-pages-company-documents-index-tsx": () => import("./../../../src/pages/company/documents/index.tsx" /* webpackChunkName: "component---src-pages-company-documents-index-tsx" */),
  "component---src-pages-company-documents-member-documents-index-tsx": () => import("./../../../src/pages/company/documents/member-documents/index.tsx" /* webpackChunkName: "component---src-pages-company-documents-member-documents-index-tsx" */),
  "component---src-pages-company-documents-scheme-information-index-tsx": () => import("./../../../src/pages/company/documents/scheme-information/index.tsx" /* webpackChunkName: "component---src-pages-company-documents-scheme-information-index-tsx" */),
  "component---src-pages-company-documents-useful-links-index-tsx": () => import("./../../../src/pages/company/documents/useful-links/index.tsx" /* webpackChunkName: "component---src-pages-company-documents-useful-links-index-tsx" */),
  "component---src-pages-company-employees-active-index-tsx": () => import("./../../../src/pages/company/employees/active/index.tsx" /* webpackChunkName: "component---src-pages-company-employees-active-index-tsx" */),
  "component---src-pages-company-employees-index-tsx": () => import("./../../../src/pages/company/employees/index.tsx" /* webpackChunkName: "component---src-pages-company-employees-index-tsx" */),
  "component---src-pages-company-employees-offboarded-index-tsx": () => import("./../../../src/pages/company/employees/offboarded/index.tsx" /* webpackChunkName: "component---src-pages-company-employees-offboarded-index-tsx" */),
  "component---src-pages-company-gratuity-index-tsx": () => import("./../../../src/pages/company/gratuity/index.tsx" /* webpackChunkName: "component---src-pages-company-gratuity-index-tsx" */),
  "component---src-pages-company-index-tsx": () => import("./../../../src/pages/company/index.tsx" /* webpackChunkName: "component---src-pages-company-index-tsx" */),
  "component---src-pages-company-profile-index-tsx": () => import("./../../../src/pages/company/profile/index.tsx" /* webpackChunkName: "component---src-pages-company-profile-index-tsx" */),
  "component---src-pages-company-reports-index-tsx": () => import("./../../../src/pages/company/reports/index.tsx" /* webpackChunkName: "component---src-pages-company-reports-index-tsx" */),
  "component---src-pages-company-savings-index-tsx": () => import("./../../../src/pages/company/savings/index.tsx" /* webpackChunkName: "component---src-pages-company-savings-index-tsx" */),
  "component---src-pages-company-signup-index-tsx": () => import("./../../../src/pages/company/signup/index.tsx" /* webpackChunkName: "component---src-pages-company-signup-index-tsx" */),
  "component---src-pages-company-welcome-index-tsx": () => import("./../../../src/pages/company/welcome/index.tsx" /* webpackChunkName: "component---src-pages-company-welcome-index-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-disclaimer-index-tsx": () => import("./../../../src/pages/disclaimer/index.tsx" /* webpackChunkName: "component---src-pages-disclaimer-index-tsx" */),
  "component---src-pages-employee-contact-us-index-tsx": () => import("./../../../src/pages/employee/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-employee-contact-us-index-tsx" */),
  "component---src-pages-employee-dashboard-employee-contribution-index-tsx": () => import("./../../../src/pages/employee/dashboard/employee-contribution/index.tsx" /* webpackChunkName: "component---src-pages-employee-dashboard-employee-contribution-index-tsx" */),
  "component---src-pages-employee-dashboard-gratuity-deposits-index-tsx": () => import("./../../../src/pages/employee/dashboard/gratuity-deposits/index.tsx" /* webpackChunkName: "component---src-pages-employee-dashboard-gratuity-deposits-index-tsx" */),
  "component---src-pages-employee-dashboard-index-tsx": () => import("./../../../src/pages/employee/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-employee-dashboard-index-tsx" */),
  "component---src-pages-employee-dashboard-net-wealth-index-tsx": () => import("./../../../src/pages/employee/dashboard/net-wealth/index.tsx" /* webpackChunkName: "component---src-pages-employee-dashboard-net-wealth-index-tsx" */),
  "component---src-pages-employee-documents-faq-index-tsx": () => import("./../../../src/pages/employee/documents/faq/index.tsx" /* webpackChunkName: "component---src-pages-employee-documents-faq-index-tsx" */),
  "component---src-pages-employee-documents-glossary-index-tsx": () => import("./../../../src/pages/employee/documents/glossary/index.tsx" /* webpackChunkName: "component---src-pages-employee-documents-glossary-index-tsx" */),
  "component---src-pages-employee-documents-index-tsx": () => import("./../../../src/pages/employee/documents/index.tsx" /* webpackChunkName: "component---src-pages-employee-documents-index-tsx" */),
  "component---src-pages-employee-documents-member-documents-index-tsx": () => import("./../../../src/pages/employee/documents/member-documents/index.tsx" /* webpackChunkName: "component---src-pages-employee-documents-member-documents-index-tsx" */),
  "component---src-pages-employee-documents-scheme-information-index-tsx": () => import("./../../../src/pages/employee/documents/scheme-information/index.tsx" /* webpackChunkName: "component---src-pages-employee-documents-scheme-information-index-tsx" */),
  "component---src-pages-employee-documents-useful-links-index-tsx": () => import("./../../../src/pages/employee/documents/useful-links/index.tsx" /* webpackChunkName: "component---src-pages-employee-documents-useful-links-index-tsx" */),
  "component---src-pages-employee-education-index-tsx": () => import("./../../../src/pages/employee/education/index.tsx" /* webpackChunkName: "component---src-pages-employee-education-index-tsx" */),
  "component---src-pages-employee-gratuity-index-tsx": () => import("./../../../src/pages/employee/gratuity/index.tsx" /* webpackChunkName: "component---src-pages-employee-gratuity-index-tsx" */),
  "component---src-pages-employee-index-tsx": () => import("./../../../src/pages/employee/index.tsx" /* webpackChunkName: "component---src-pages-employee-index-tsx" */),
  "component---src-pages-employee-payments-additional-payments-index-tsx": () => import("./../../../src/pages/employee/payments/additional-payments/index.tsx" /* webpackChunkName: "component---src-pages-employee-payments-additional-payments-index-tsx" */),
  "component---src-pages-employee-payments-amend-contributions-index-tsx": () => import("./../../../src/pages/employee/payments/amend-contributions/index.tsx" /* webpackChunkName: "component---src-pages-employee-payments-amend-contributions-index-tsx" */),
  "component---src-pages-employee-payments-contribution-history-index-tsx": () => import("./../../../src/pages/employee/payments/contribution-history/index.tsx" /* webpackChunkName: "component---src-pages-employee-payments-contribution-history-index-tsx" */),
  "component---src-pages-employee-payments-index-tsx": () => import("./../../../src/pages/employee/payments/index.tsx" /* webpackChunkName: "component---src-pages-employee-payments-index-tsx" */),
  "component---src-pages-employee-payments-transaction-history-index-tsx": () => import("./../../../src/pages/employee/payments/transaction-history/index.tsx" /* webpackChunkName: "component---src-pages-employee-payments-transaction-history-index-tsx" */),
  "component---src-pages-employee-profile-beneficiaries-index-tsx": () => import("./../../../src/pages/employee/profile/beneficiaries/index.tsx" /* webpackChunkName: "component---src-pages-employee-profile-beneficiaries-index-tsx" */),
  "component---src-pages-employee-profile-index-tsx": () => import("./../../../src/pages/employee/profile/index.tsx" /* webpackChunkName: "component---src-pages-employee-profile-index-tsx" */),
  "component---src-pages-employee-profile-manage-profile-index-tsx": () => import("./../../../src/pages/employee/profile/manage-profile/index.tsx" /* webpackChunkName: "component---src-pages-employee-profile-manage-profile-index-tsx" */),
  "component---src-pages-employee-profile-membership-details-index-tsx": () => import("./../../../src/pages/employee/profile/membership-details/index.tsx" /* webpackChunkName: "component---src-pages-employee-profile-membership-details-index-tsx" */),
  "component---src-pages-employee-risk-profile-index-jsx": () => import("./../../../src/pages/employee/risk-profile/index.jsx" /* webpackChunkName: "component---src-pages-employee-risk-profile-index-jsx" */),
  "component---src-pages-employee-signup-index-tsx": () => import("./../../../src/pages/employee/signup/index.tsx" /* webpackChunkName: "component---src-pages-employee-signup-index-tsx" */),
  "component---src-pages-employee-wealth-account-index-tsx": () => import("./../../../src/pages/employee/wealth-account/index.tsx" /* webpackChunkName: "component---src-pages-employee-wealth-account-index-tsx" */),
  "component---src-pages-employee-wealth-account-portfolio-index-tsx": () => import("./../../../src/pages/employee/wealth-account/portfolio/index.tsx" /* webpackChunkName: "component---src-pages-employee-wealth-account-portfolio-index-tsx" */),
  "component---src-pages-employee-wealth-account-your-gratuity-index-tsx": () => import("./../../../src/pages/employee/wealth-account/your-gratuity/index.tsx" /* webpackChunkName: "component---src-pages-employee-wealth-account-your-gratuity-index-tsx" */),
  "component---src-pages-employee-wealth-account-your-savings-index-tsx": () => import("./../../../src/pages/employee/wealth-account/your-savings/index.tsx" /* webpackChunkName: "component---src-pages-employee-wealth-account-your-savings-index-tsx" */),
  "component---src-pages-faq-index-tsx": () => import("./../../../src/pages/faq/index.tsx" /* webpackChunkName: "component---src-pages-faq-index-tsx" */),
  "component---src-pages-gratuity-manager-index-tsx": () => import("./../../../src/pages/gratuity-manager/index.tsx" /* webpackChunkName: "component---src-pages-gratuity-manager-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-index-tsx": () => import("./../../../src/pages/landing/index.tsx" /* webpackChunkName: "component---src-pages-landing-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-password-recovery-index-tsx": () => import("./../../../src/pages/password-recovery/index.tsx" /* webpackChunkName: "component---src-pages-password-recovery-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-terms-and-conditions-index-tsx": () => import("./../../../src/pages/terms-and-conditions/index.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-tsx" */),
  "component---src-pages-workplace-savings-index-tsx": () => import("./../../../src/pages/workplace-savings/index.tsx" /* webpackChunkName: "component---src-pages-workplace-savings-index-tsx" */),
  "component---src-templates-blog-blog-tsx": () => import("./../../../src/templates/blog/blog.tsx" /* webpackChunkName: "component---src-templates-blog-blog-tsx" */)
}

