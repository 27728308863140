import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'state/store';


interface LayoutState {
    screenSize: "s" | "m" | "l" | undefined;
    defaultSidebarIsOpen: boolean | undefined;
    dashboardMenuSize: "hidden" | "collapsed" | "full" | undefined;
    signupLoginDialogIsOpen: boolean;
}

const initialState: LayoutState = {
    screenSize: undefined,
    defaultSidebarIsOpen: false,
    dashboardMenuSize: "hidden",
    signupLoginDialogIsOpen: false
};

// Slice
const LayoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setScreenSize: (state, action: PayloadAction<"s" | "m" | "l">) => {
            const { payload } = action;
            state.screenSize = payload;
        },
        toggleDefaultSidebar: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.defaultSidebarIsOpen = payload;
        },
        setDashboardMenuSize: (state, action: PayloadAction<"hidden" | "collapsed" | "full">) => {
            const { payload } = action;
            state.dashboardMenuSize = payload;
        },
        toggleDashboardMenuSize: (state) => {
            if (state.screenSize === "s") {
                if (state.dashboardMenuSize === "full") {
                    state.dashboardMenuSize = "hidden"
                } else {
                    state.dashboardMenuSize = "full"
                }
            } else {
                if (state.dashboardMenuSize === "full") {
                    state.dashboardMenuSize = "collapsed"
                } else {
                    state.dashboardMenuSize = "full"
                }
            }
        },
        setSignupLoginDialogIsOpen: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.signupLoginDialogIsOpen = payload;
        },
    }
});

// Reducers
export default LayoutSlice.reducer;

// Selectors
export const layoutSelector = (state: RootState) => state.layout;

// Actions
export const { setScreenSize, toggleDefaultSidebar, setDashboardMenuSize, toggleDashboardMenuSize, setSignupLoginDialogIsOpen } = LayoutSlice.actions;