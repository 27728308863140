import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'state/store';


interface AuthState {
    signupToken: String;
    loginToken: String;
    passwordRecoveryToken: String;
    token: String;
    user: any,
}

const initialState: AuthState = {
    token: "",
    signupToken: "",
    loginToken: "",
    passwordRecoveryToken: "",
    user: null,
};

// Slice
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setSignupToken: (state, action: PayloadAction<String>) => {
            const { payload } = action;
            state.signupToken = payload;
        },
        setLoginToken: (state, action: PayloadAction<String>) => {
            const { payload } = action;
            state.loginToken = payload;
        },
        setPasswordRecoveryToken: (state, action: PayloadAction<String>) => {
            const { payload } = action;
            state.passwordRecoveryToken = payload;
        },
        setToken: (state, action: PayloadAction<String>) => {
            const { payload } = action;
            state.token = payload;
        },
        setUser: (state, action: PayloadAction<any>) => {
            const { payload } = action;
            state.user = payload;
        }
    }
});

// Reducers
export default authSlice.reducer;

// Selectors
export const authSelector = (state: RootState) => state.auth;

// Actions
export const { setSignupToken, setLoginToken, setPasswordRecoveryToken, setToken, setUser } = authSlice.actions;
