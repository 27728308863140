import { combineReducers, createSlice } from '@reduxjs/toolkit';

const dommySlice = createSlice({
    name: 'Dommy',
    initialState: {},
    reducers: {
        someAction: function () {

        }
    }
})

const rootReducer = combineReducers({
    dommyReducer: dommySlice.reducer
});

export default rootReducer;