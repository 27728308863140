import { configureStore, combineReducers, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import authReducer from 'state/authentication';
import layoutReducer from 'state/layout';
import rootEmployeeReducer from '@state/employee';
import rootCompanyReducer from '@state/company';

const rootReducer = combineReducers({
    auth: authReducer,
    layout: layoutReducer,
    company: rootCompanyReducer,
    employee: rootEmployeeReducer
});

const store = configureStore({
    reducer: rootReducer
});

export type RootState = ReturnType<typeof store.getState>;
export type Dispatch = typeof store.dispatch;
export type Thunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
