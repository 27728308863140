import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'state/store';


interface riskProfileState {
    complete: boolean;
    activeQuestion: number;
    questions: any[];
    answer: {};
    averageScore: number;
}

const initialState: riskProfileState = {
    complete: false,
    activeQuestion: 0,
    questions: [],
    answer: {},
    averageScore: 0
};

// Slice
const RiskProfileSlice = createSlice({
    name: 'riskProfile',
    initialState,
    reducers: {
        setComplete: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.complete = payload;
        },
        setActiveQuestion: (state, action: PayloadAction<number>) => {
            const { payload } = action;
            state.activeQuestion = payload;
        },
        setQuestions: (state, action: PayloadAction<any[]>) => {
            const { payload } = action;
            state.questions = payload;
        },
        updateAnswer: (state, action: PayloadAction<{ index: string, value: number }>) => {
            const { payload } = action;
            state.answer = { ...state.answer, [payload.index]: payload.value };
        },
        setAverageScore: (state, action: PayloadAction<number>) => {
            const { payload } = action;
            state.averageScore = Number(payload);
        },
    }
});

// Reducers
export default RiskProfileSlice.reducer;

// Selectors
export const riskProfileSelector = (state: RootState) => state.employee.riskProfile;

// Actions
export const { setComplete, setActiveQuestion, setQuestions, updateAnswer, setAverageScore } = RiskProfileSlice.actions;